body{
  background-color: black;
}

@media print {
  nav{display: none;}
  .printable,.printable *{
      display: block; 
  }
  .nonPrintable,.nonPrintable *{
    display: none; 
}
/* body{background-color: transparent;} */
}

.page {
  page-break-after:always; /* Ensure pages break correctly when printing */
}

.circular-chart {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: var(--conicGradientDynamic);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
  position: relative;
}

/* Desktop */
@media(min-aspect-ratio:1/1){
  .minimalPadding{padding: 1vw;}
  .minimalPadding0{padding: 2vw;}
  .minimalPaddingXTop{padding-left: 1vw; padding-right: 1vw; padding-bottom: 1vw; }
  .minimalPaddingXLeftXRight{padding-top: 1vw; padding-bottom: 1vw; }
  .minimalPaddingXTopXBottom{padding-left: 1vw; padding-right: 1vw; }
  .minimalMargin{margin: 1vw;}
  .minimalMarginXTop{margin-left: 1vw; margin-right: 1vw; margin-bottom: 1vw; }
  .minimalMarginBottom{margin-bottom: 1vw;}
  .minimalMargin0{margin: 2vw;}
  .minimalMargin1{margin: 4vw;}
  .navMenuBranding{color:white; background-color: black;}

  h1{font-size: 5vh;}
  
  .readableFontSize{font-size: 2vw;}
  .minimalIconSize{width: 4vh;height: 4vh;}
  .readableSymbolSize{font-size: 2.5vw;}
  .touchAppSize{width: 2vh;height: 2vh;}

  .motoFontSize{font-size: 5vw;}

  .forwardBackArrow{
    width: 5vw;
    height: 5vw;
    opacity: 0.5;
  }

  .circleButton{
    padding:0;
    height: 1vw;
    width: 1vw;
    border-radius: 50%;
  }

  .signUpInput{
    width: 50vw;
    height: 5vh;
    font-size: 3vh;
    padding: 1vw;
  }

  .signUpInputHolder{
    width: 50vw;
    padding: 1vw;
  }
  .signUpInputPassword{
    width: 100%;
    height: 5vh;
    font-size: 3vh;
  }
  .signUpInputPasswordVisibility{
    height: 5vh;
  }

  .signUpButton{
    font-size: 4vh;
  }
  .signUpStatus{
    font-size: 3vh;
  }
  .logoSize{
    width: 1vw;
    height: 1vw;
  }
  .logoFontSize{
    font-size: 0.8vw;
  }

  .minimalBorderWidth{border-width: 0.2vw;}
  .minimalBorderRadius{border-radius: 1vw;}
  .minimalBorderRadiusButton{border-radius: 1vw;}
}

/* Mobile */
@media(max-aspect-ratio:1/1){
  .minimalPadding{padding: 2vw;}
  .minimalPadding0{padding: 4vw;}
  .minimalPaddingXTop{padding-left: 2vw; padding-right: 2vw; padding-bottom: 2vw; }
  .minimalPaddingXLeftXRight{padding-top: 2vw; padding-bottom: 2vw; }
  .minimalPaddingXTopXBottom{padding-left: 2vw; padding-right: 2vw; }
  .minimalMargin{margin: 2vw;}
  .minimalMargin0{margin: 4vw;}
  .minimalMargin1{margin: 8vw;}
  .minimalMarginXTop{margin-left: 2vw; margin-right: 2vw; margin-bottom: 2vw; }
  .minimalMarginBottom{margin-bottom: 2vw;}
  .navMenuBranding{color:white; background-color: black;}

  h1{font-size: 6vw;}
  .readableFontSize{font-size: 3vw;}
  .minimalIconSize{width: 7vw;height: 7vw;}
  .readableSymbolSize{font-size: 4vw;}
  .touchAppSize{width: 5vw;height: 5vw;}

  .motoFontSize{
    font-size: 6vw;
  }

  .forwardBackArrow{
    width: 8vw;
    height: 8vw;
    opacity: 0.5;
  }

  .circleButton{
    padding:0;
    height: 2vw;
    width: 2vw;
    border-radius: 50%;
  }

  .signUpInput{
    width: 80vw;
    height: 8vh;
    font-size: 2.5vh;
  }
  .signUpInputHolder{
    width: 80vw;
  }
  .signUpInputPassword{
    width: 100%;
    height: 8vh;
    font-size: 2.5vh;
  }

  .signUpInputPasswordVisibility{
    height: 8vh;
  }

  .signUpButton{
    font-size: 3.5vh;
  }
  .signUpStatus{
    font-size: 2vh;
  }
  .logoSize{
    width: 5vw;
    height: 5vw;
  }
  .logoFontSize{
    font-size: 4vw;
  }

  .minimalBorderWidth{border-width: 0.2vw;}
  .minimalBorderRadius{border-radius: 5vw;}
  .minimalBorderRadiusButton{border-radius: 2vw;}
}

.minHeight50vh{
  min-height: 50vh;
}

.minHeight100vh{
  min-height: 100vh;
}


.outlineNone{
  outline: none;
}

.menuButton{
  padding: 0;
  border-style: none;
}
.menuImage{
  width: 10vw;
  height: 10vw;
  background-color: white;
}


.textDecorationUnderlineColorGreen0{
  text-decoration: underline;
  text-decoration-color: rgb(55, 160, 7) ;
}

.text-border {
  text-shadow: 
    -5px -5px 0 rgb(179,179,179), /* Top-left shadow */
    5px -5px 0 rgb(179,179,179),  /* Top-right shadow */
    -5px 5px 0 rgb(179,179,179),  /* Bottom-left shadow */
    5px 5px 0 rgb(179,179,179);   /* Bottom-right shadow */
}


.placeHolderOpacity::placeholder{
  opacity: 0.5;
}

.placeHolderColorGreen0::placeholder{
  color: rgb(55, 160, 7);
}

.textAlignJustify{text-align: justify;}
.textAlignLeft{text-align: left;}
.textAlignRight{text-align: right;}

.userSelectNone{user-select: none;}
.pointerEventsNone{pointer-events:none;}

.colorBlack50Per{color:rgba(0, 0, 0, 0.5);}
.borderColorBlack50Per{border-color: rgba(0, 0, 0, 0.5);}


.overflowXHidden{overflow-x:hidden;}
.overflowYHidden{overflow-y:hidden;}
.overflowHidden{overflow:hidden;}
.touchActionNone{touch-action: none;}
.touchActionPanX{touch-action: pan-x;}
.touchActionPanY{touch-action: pan-y;}

.listStyleSquare{list-style: square;}

.fontStyleItalic{font-style: italic;}
.textDecorationUnderline{text-decoration: underline;}
.textDecorationNone{text-decoration: none;}

.fontWeightBold{font-weight: bold;}
.fontWeightNormal{font-weight: normal;}

.readAbleFontSize{font-size: 3vw;}

.displayNone{display: none;}
.displayBlock{display:block;}


.displayFlex{display: flex;}
.flexDirectionColumn{flex-direction: column;}
.justifyContentCenter{justify-content: center;}
.alignItemsCenter{align-items: center;}

.positionAbsolute{position: absolute;}
.positionRelative{position: relative;}
.left0{left:0;}
.right0{right: 0;}
.top0{top:0;}
.bottom0{bottom: 0;}
.positionFixed{position: fixed;}

.cursorPointer{cursor: pointer;}
.userSelectNone{user-select: none;}

.listStyleTypeNone{list-style-type:none;}

.margin0{margin:0;}
.marginLeft0{margin-left: 0;}
.marginRight0{margin-right: 0;}
.margin5vh{margin:5vh;}
.marginTop0{margin-top: 0;}

.padding0{padding:0;}
.padding1vw{padding: 1vw;}
.padding2vw{padding: 2vw;}
.paddingLeft0{padding-left: 0;}
.paddingRight0{padding-right: 0;}
.paddingTop0{padding-top: 0;}

.border0{border:0;}
.borderRadius1vw{border-radius: 1vw;}
.borderWidth1vw{border-width: 1vw;}
.borderColorWheat{border-color: wheat; }
.borderStyleSolid{border-style: solid;}
.borderColorWhite{border-color: white;}
.borderColorBlack{border-color: black;}
.borderColorGreen0{border-color: rgb(55, 160, 7);}
.borderColorWhiteSmoke{border-color: whitesmoke;}
.borderBottomStyleSolid{border-bottom-style: solid;}
.borderStyleNone{border-style: none;}


.backgroundTransparent{background: transparent;}
.backgroundBlack{background: black;}
.backgroundColorWhite80Per{background-color: rgba(255, 255, 255,0.8);}
.backgroundColorWhite50Per{background-color: rgba(255, 255, 255,0.5);}
.backgroundWhite{background: white;}
.backgroundWhitesmoke{background: whitesmoke;}
.backgroundWheat{background-color: wheat; }
.backgroundBlue0{background-color: rgb(17, 87, 134);}
.backgroundGreen0{background: rgb(55, 160, 7);}
.backgroundRed{background: red;}
.colorWhite{color:white;}
.colorBlack{color:black;}
.colorBlue0{color:rgb(17, 87, 134);}
.colorRed{color: red;}
.colorGray{color: rgb(179,179,179);}
.colorGreen0{color:rgb(55, 160, 7);}
.backgroundWhiteFadeIn{background: rgb(255, 255, 255);}
.backgroundWheatFadeIn{background-color: rgba(245, 222, 179);}


.backgroundColorFadeIn{
  animation-name: backgroundColorFadeIn;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}
@keyframes backgroundColorFadeIn {
  0%{

  }

  100%{
    background-color: var(--backgroundColorInput);
  }

}

.H5vh{height: 5vh;}
.H10vh{height: 10vh;}
.H50vh{height:50vh;}
.H60vh{height: 60vh;}
.H100vh{height: 100vh;}
.H6Per{height:6%;}
.H8Per{height:8%;}
.H10Per{height: 10%;}
.H20Per{height: 20%;}
.H47Per{height: 47%;}
.H50Per{height: 50%;}
.H80Per{height: 80%;}
.H90Per{height: 90%;}
.H94Per{height: 94%;}
.H100Per{height: 100%;}

.W5vw{width: 5vw;}
.W10vw{width: 10vw;}
.W20vw{width: 20vw;}
.W25vw{width: 25vw;}
.W30vw{width: 30vw;}
.W40vw{width: 40vw;}
.W45vw{width: 45vw;}
.W50vw{width: 50vw;}
.W55vw{width: 55vw;}
.W60vw{width: 60vw;}
.W70vw{width: 70vw;}
.W80vw{width: 80vw;}
.W90vw{width: 90vw;}
.W100vw{width: 100vw;}
.W100Per{width: 100%;}

.fontSize5vh{font-size:5vh;}
.justifyContentRight{justify-content: right;}
.textAlignCenter{text-align: center;}
.textAlignRight{text-align: right;}



.slideOutY0{
  animation-name: slideOut0;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

@keyframes slideOutY0 {
  from {
    
  }

  to {
    transform: translateY(50vh);
  }
}

.slideOutY1{
  animation-name: slideOutY1;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

@keyframes slideOutY1 {
  from {
    
  }

  to {
    transform: translateY(50vh);
  }
}

.slideIn{
  animation-name: slideIn;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(-50vw);
  }

  to {
    transform: translateX(45vw);
  }
}


.fadeInOut0{
  animation-name: fadeInOut0;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

@keyframes fadeInOut0{
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
  
  100% {
      opacity: 0;
    }
  }


.fadeInOut1{
  animation-name: fadeInOut1;
  animation-duration: 4s;
}

@keyframes fadeInOut1{



  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
  
  100% {
      opacity: 0;
    }
  }


.colorEffect{
  animation-name: colorEffect;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

@keyframes colorEffect{
  0%{
    opacity: 0.5;
  }
  
  100% {
    opacity: 1;
    color: rgb(55, 160, 7);
    }
  }


  .slantEffect{
    animation-name: slantEffect;
    animation-duration: 5s;
    animation-fill-mode: forwards;
  }
  
  @keyframes slantEffect{
    0%{
      transform: rotate(0deg);
    }
    
    100% {
      transform: rotate(-30deg);
      }
    }